import React from 'react';
import banner from "../img/escuela-alcaldes-amayuelas.jpg";
import mapa from "../img/mapa-amayuelas.jpg";
import "../styles.css";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" alt="4ª Jornada de Escuela de Alcaldes" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="border-light mb-3 centrado" role="alert">
					<h5>4ªJORNADA DE ESCUELA DE ALCALDES 2023</h5>
					<h6>Tema: “La sostenibilidad ambiental desde el punto de vista social-participación ciudadana”</h6>
					<h6>ENTIDAD: <strong>FUNDACIÓN SAN ISIDRO MICAS.<br></br>CALZADA DE LOS MOLINOS (PALENCIA)</strong></h6>
					<h6>Proyecto: <i>«Horticultura Ecosolidaria 2020/2021»</i></h6>
					<hr></hr>
					<h6><strong>Fecha:</strong> 19 de septiembre de 2023</h6>
					<h6><strong>Lugar:</strong> Plaza de la Iglesia 7 Amayuelas de Abajo (Palencia)</h6>
					</div>
				</div>
			</div>
			<div className="card text-white w-100 caracteristicas colorfondo">
				<div className="card-body">
					<h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
					<a href="#form"><p><strong>RESERVA TU PLAZA</strong></p></a>
					<p>Inscripciones hasta el viernes 15 de septiembre a las 14:00 h.</p>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card border-secondary w-100 caracteristicas izquierda">
					<div className="card-body">
						<h5 className="card-title centrado">Agenda:</h5>
						<li><strong className='colortext'>9:45-10:00 h.- </strong> Recepción y acreditación de participantes.</li>
						<li><strong className='colortext'>10:00-10:15 h.- </strong> Saluda de autoridades y explicación de la evolución de la jornada.</li>
						<li><strong className='colortext'>10:15-10:45 h.- </strong>Presentación del proyecto premiado “Horticultura Ecosolidaria 2020/2021“.
							<ul>
								<p><i>D. José Manuel Miguel Castrillo, Presidente Fundación San Isidro Micas. Calzada de los Molinos (Palencia).</i></p>
							</ul>
						</li>
						<li><strong className='colortext'>10:45–11:15 h.- </strong>Ponencia: “Agricultura ecológica, una herramienta ecosocial. Conservación del medio ambiente, gestión sostenible del territorio, revitalización, transformación y cohesión social”.
							<ul>
								<p><i>David Tutor de la Iglesia, Consultor en Educación Ambiental en Erde y Formador del Programa Mixto “Agricultura Eco-solidaria”.
Descripción: La producción agrícola puede servir de piedra angular para aunar la actividad económica con la sostenibilidad respetando el medio ambiente, pero también fomentando el desarrollo local de comunidades prósperas y vitalizadas en el medio rural.</i></p>
							</ul>
						</li>
						<li><strong className='colortext'>11:15-11:45 h.- </strong> Pausa para café.
							<ul>
								<p><i>Albergue-restaurante “El patio de Amayuelas” Plaza de la Iglesia 7.</i></p>
							</ul>
						</li>
						<li><strong className='colortext'>11:45-13:45.- </strong>  Dinámica participativa y puesta en común de ideas: “Fórmulas de aprovechamiento de los recursos autóctonos y los saberes tradicionales. Desde la agricultura ecológica a la construcción tradicional”.
							<ul>
								<p><i>David Tutor de la Iglesia, Consultor en Educación Ambiental en Erde y Formador del Programa Mixto “Agricultura Eco-solidaria”.
Descripción: Se trabajará una dinámica para detectar recursos autóctonos y saberes tradicionales susceptibles de aprovechamiento sostenible, así como para compartir formulas partiendo de las experiencias de los asistentes y ejemplos de referencia.</i></p>
							</ul>
						</li>
						<li><strong className='colortext'>13:45–14:00 h.- </strong> Reflexión, evaluación y clausura de la jornada.
							<ul>
								<p><i>Contextualización del proyecto e iniciativas presentadas en la jornada, en los pilares de la sostenibilidad.
D. Pablo Labajos Martín, consultor en sostenibilidad y educación ambiental.</i></p>
							</ul>
						</li>
						<li><strong className='colortext'>14:00-15:00 h.- </strong> Visita del proyecto premiado.
							<ul>
								<p><i>En un paseo por la huerta junto con los alumnos y profesores y responsables del proyecto nos mostrarán y explicarán varias de las acciones realizadas dentro del proyecto.</i></p>
							</ul>
						</li>
						<li><strong className='colortext'>15:00-16:30 h.- </strong> Comida.
							<ul>
								<p><i>Albergue-restaurante “El patio de Amayuelas” Plaza de la Iglesia 7.</i></p>
							</ul>
						</li>
						<li><strong className='colortext'>16:30-17:30 h.- </strong> Visita al Banco de semillas autóctonas de Amayuelas de Abajo.
							<ul>
								<p><i>Ante la preocupación por la desaparición progresiva de variedades de cultivo tradicionales, se ha reconvertido un antiguo palomar en un banco de semillas. Cada nidal del palomar alberga una especie.
								</i></p>
							</ul>
						</li>
					</div>
					</div>
				</div>
			</div>
			<div className="row inscripcion" id="formulario">
				<div className="col-12">
					<div className="alert alert-light text-center" role="alert" id="form">
						<h3>Formulario de inscripción</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/787" width="95%" height="1300px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>						
					</div>
				</div>
			</div>
			<hr></hr>
			<div className='container'>
			<div className='row'>
			<div class="card mb-3">
			<div class="row g-0">
				<div class="col-md-4">
					<a href='https://www.google.com/maps/d/edit?mid=1sOXQvIzc0eBRTDNbkogqDoYo_OJ8SWE&usp=sharing'>
						<img src={mapa} class="img-fluid rounded-start" alt="..."/>
					</a>
				</div>
				<div class="col-md-8">
				<div class="card-body">
				<h5 class="card-title colortext">UBICACIÓN</h5>
							<ul class="list-group list-group-flush">
								<li class="list-group-item">Parking (marcador azul)</li>
								<li class="list-group-item">Nave en donde se celebrará la Jornada si el tiempo no permite hacerlo al aire libre en el Proyecto Premiado (marcador verde)</li>
								<li class="list-group-item">Albergue-restaurante “El patio de Amayuelas” Plaza de la Iglesia 7 (marcador rojo)</li>
								<li class="list-group-item">Proyecto Premiado (marcador morado)</li>
								<li class="list-group-item">Banco de semillas (marcador amarillo)</li>
							</ul>
				</div>
				</div>
			</div>
			</div>
			</div>
		</div>
		<div className='container-fluid'>
				<div className="row">
					<div className="col-12 info">
						Más información <i class="fas fa-phone-square-alt"></i>
						&nbsp;979 10 83 03&nbsp;
						<i class="fas fa-paper-plane"></i>&nbsp;inscripciones@itagraformacion.com
					</div>
				</div>
			</div>
		</div>
)

export default Home